<template>
    <div>
        <select
            class="type-filter width-100"
            v-model="dateFilterListView"
            :aria-label="`Sort ${connectedEntityTitle}`"
        >
            <option value="all">{{ $t("EntityList.sort_by") }}:</option>
            <option value="asc">
                {{ $t("EntityList.alphabetical") }} (A to Z)
            </option>
            <option value="desc">
                {{ $t("EntityList.alphabetical") }} (Z to A)
            </option>
        </select>
        <div class="entity-details-scrollbox" role="list">
            <div
                class="entity-details-wrapper"
                v-for="(entity, index) in sortedEntitiesInListView"
                :key="index"
                role="listitem"
            >
                <EntityResourceCard
                    v-if="!annotationsView"
                    :data="entity"
                    :index="index"
                    :currentExpandedIndex="expandedEntityIndex"
                    v-on:update="
                        (values: any) =>
                            ($parent as any).handleToggleOfDetails(
                                values.index,
                                values.mainEntity,
                            )
                    "
                />
                <AnnotationCard
                    v-else
                    :label="entity.annotationLabel"
                    :target="getWebReferenceTarget(entity) ?? ''"
                    :dataset="datasetLabelMap[entity.graph] ?? entity.graph ?? ''"
                    :index="index"
                    v-on:update="
                        (annotationInfo: any) =>
                            ($parent as any).displayWebReference(
                                sortedEntitiesInListView[annotationInfo.index],
                            )
                    "
                    v-on:change="
                        (annotationInfo: any) =>
                            (($parent as any).annotationIconMap[
                                entity.annotation
                            ] = annotationInfo.image)
                    "
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import EntityResourceCard from "../components/EntityResourceCard.vue";
import AnnotationCard from "../components/AnnotationCard.vue";

@Options({
    props: {
        connectedEntityTitle: String,
        categoryToDisplay: Object,
        annotationsView: Boolean,
        expandedEntityIndex: Number,
    },
    components: {
        EntityResourceCard,
        AnnotationCard,
    },
    watch: {
        categoryToDisplay() {
            if (this.categoryToDisplay && this.annotationsView) {
                this.getGraphLabels();
            }
        }
    }
})
export default class EntityList extends Vue {
    connectedEntityTitle!: string;
    categoryToDisplay!: any;
    annotationsView!: boolean;
    expandedEntityIndex!: number;

    dateFilterListView = "all";
    datasetLabelMap:{[key: string]: string|null} = {};

    // This is the url for filling out the dataset labels
    apiURL = 
        "https://lincs-api.lincsproject.ca/api/dataset";

    // This is the authToken needed for the lincs-api's rate limiting
    authToken = 
        "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJVcG5aOHZaTGNUemhTMWtzdGN2NDVkR205QXYwUmlTbElaQ0YzZEhxR1JRIn0.eyJleHAiOjE3NDIzMjMyMjcsImlhdCI6MTcxMDc4NzIyNywianRpIjoiMjU2OTU1YjUtOTkzNi00OGZmLThjMmMtYjdkOWRiMTliZjc0IiwiaXNzIjoiaHR0cHM6Ly9rZXljbG9hay5saW5jc3Byb2plY3QuY2EvcmVhbG1zL2xpbmNzIiwiYXVkIjpbInZlcnNkLXVpIiwicmVzZWFyY2hzcGFjZSIsImFkbWluLWNsaSIsImJyb2tlciIsImFjY291bnQiXSwic3ViIjoiYmQ5MTRkMTEtZmVkMi00ODllLWI0MGQtNDk0YjkzZDFmOGU4IiwidHlwIjoiQmVhcmVyIiwiYXpwIjoibGluY3MtYXBpIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyIvKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsiVXNlciIsImRlZmF1bHQtcm9sZXMtbGluY3MiLCJvZmZsaW5lX2FjY2VzcyIsInVtYV9hdXRob3JpemF0aW9uIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsidmVyc2QtdWkiOnsicm9sZXMiOlsidXNlciJdfSwicmVzZWFyY2hzcGFjZSI6eyJyb2xlcyI6WyJjb250cmlidXRvciIsImd1ZXN0Il19LCJhZG1pbi1jbGkiOnsicm9sZXMiOlsiYWRtaW4iXX0sImJyb2tlciI6eyJyb2xlcyI6WyJyZWFkLXRva2VuIl19LCJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6ImVtYWlsIHByb2ZpbGUgYXZhdGFyX3VybCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiY2xpZW50SG9zdCI6IjcwLjc1LjExMi4xMSIsInByZWZlcnJlZF91c2VybmFtZSI6InNlcnZpY2UtYWNjb3VudC1saW5jcy1hcGkiLCJjbGllbnRBZGRyZXNzIjoiNzAuNzUuMTEyLjExIiwiY2xpZW50X2lkIjoibGluY3MtYXBpIn0.ivjIKG0hWe5ULAseATO4RYUmR8GCEZaTnbE70zoT-xdNxz-jXZcGmTLnGf_t34X3BEX13u-unCPGv7T5M5Wnn6f_ohG5WW8H18oSxiAflSLue3TEpgEODACXS2rzP8gDODof2cC1MSDL9E_YI4lpQ6XAl8aMb-ojhzjZ4RJUbjXq3epQ-rmvg2R5DYtKSPtn8f1o2zooGVZg8lO_Ymi9tAxqr7PsayMXExsD2R_SShrUD9DS-1Vfvrh8a1dxdquUtZ1GHc90Ms08BxdV8hBe0Q8UvQorZgQ6dOUnKUId6250qcENqXjvbttN--FPt4z8BZDphaA38Itm-q0UV4YesQ";

    // Entities sorted by alphabetic order
    get sortedEntitiesInListView() {
        if (
            this.dateFilterListView === "asc" ||
            this.dateFilterListView === "desc"
        ) {
            if (this.categoryToDisplay) {
                const sortedEntities = [...this.categoryToDisplay];
                if (!this.annotationsView) {
                    this.sortEntitiesByString(
                        sortedEntities,
                        "resourceLabel",
                        this.dateFilterListView,
                    );
                } else {
                    this.sortEntitiesByString(
                        sortedEntities,
                        "annotationLabel",
                        this.dateFilterListView,
                    );
                }
                return sortedEntities;
            }
        }
        return this.categoryToDisplay;
    }

    mounted() {
        if (this.categoryToDisplay && this.annotationsView) {
            this.getGraphLabels();
        }
    }

    // Query for graph labels for a better display
    getGraphLabels() {
        for (const entity of this.categoryToDisplay) {
            if (entity.graph && !Object.keys(this.datasetLabelMap).includes(entity.graph)) {
                this.datasetLabelMap[entity.graph] = entity.graph;

                fetch(this.apiURL, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        'Authorization': this.authToken,
                    },
                    method: "POST",
                    body: JSON.stringify({
                        uri: entity.graph,
                        language: this.$i18n.locale
                    }),
                })
                .then((response) => {
                    if (response.ok) {
                    return response.json();
                    }
                    throw new Error("FAILURE");
                })
                .then((response) => {
                    for (const pred of response) {
                        if (pred.predicate == "http://www.cidoc-crm.org/cidoc-crm/P1_is_identified_by") {
                            this.datasetLabelMap[entity.graph] = pred.object;
                            break;
                        }
                    }
                })
                .catch(() => {
                    console.log("Query Error");
                });
            }
        }
    }

    // Sort the list view of the connected entities alphabetically
    sortEntitiesByString(arr: any[], property: string, order: string) {
        if (order === "asc") {
            return arr.sort((a, b) => {
                const textA = a[property]?.toLowerCase();
                const textB = b[property]?.toLowerCase();
                return textA?.localeCompare(textB);
            });
        } else if (order === "desc") {
            return arr.sort((a, b) => {
                const textA = a[property]?.toLowerCase();
                const textB = b[property]?.toLowerCase();
                return textB?.localeCompare(textA);
            });
        } else {
            return arr;
        }
    }

    // Parse an annotation and return the target
    getWebReferenceTarget(annotation: any) {
        if (annotation.digitalObjects.length == 0) {
            return "";
        } else {
            return annotation.digitalObjects[0].uri;
        }
    }
}
</script>
<style>
.entity-details-scrollbox {
    padding-bottom: 50px;
}
</style>
