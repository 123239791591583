<template>
    <div class="dataset-warning">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16" fill="none" class="dataset-warning-item">
            <path d="M7 11.8515C7 12.1877 7.10146 12.4631 7.30438 12.6779C7.50731 12.8926 7.76299 13 8.07143 13C8.37175 13 8.62338 12.8926 8.8263 12.6779C9.03734 12.4631 9.14286 12.1877 9.14286 11.8515C9.14286 11.5154 9.03734 11.2446 8.8263 11.0392C8.62338 10.8338 8.37175 10.7311 8.07143 10.7311C7.76299 10.7311 7.50731 10.8338 7.30438 11.0392C7.10146 11.2446 7 11.5154 7 11.8515ZM7.17045 8.77591C7.17045 9.1441 7.46893 9.44258 7.83712 9.44258H8.29356C8.66175 9.44258 8.96023 9.1441 8.96023 8.77591V3.66667C8.96023 3.29848 8.66175 3 8.29356 3H7.83712C7.46893 3 7.17045 3.29848 7.17045 3.66667V8.77591Z" fill="#083943"/>
            <circle cx="8" cy="8" r="6.5" stroke="#083943"/>
        </svg>
        <div class="dataset-warning-message">
            <h2>
                Show all datasets
            </h2>
            <p>
                You have filtered datasets.
            </p>
        </div>
        <div class="dataset-warning-item">
            <label class="custom-switch">
                <input type="checkbox" role="switch" v-model="switchValue" v-on:change="toggleActiveFilters()"/>
                <span class="slider-round"></span>
            </label>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
    props: {
        initialSwitchValue: Boolean,
    },
    emits: ["datasetFilterUpdate"],
})
export default class DatasetFilterWarning extends Vue {
    initialSwitchValue!: boolean;
    switchValue = false;

    mounted() {
        this.switchValue = this.initialSwitchValue;
    }

    toggleActiveFilters() {
        this.$emit("datasetFilterUpdate", this.switchValue);
    }
}
</script>
<style>
.custom-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.custom-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-switch .slider-round {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.custom-switch .slider-round:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.custom-switch input:checked+.slider-round {
  background-color: #083943;
}

.custom-switch input:focus+.slider-round {
  box-shadow: 0 0 1px #083943;
}

.custom-switch input:checked+.slider-round:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.dataset-warning {
    display: flex;
    align-items: center;
    margin: 0px 10px 4px 10px;
    background-color: #FDF9ED;
    border: #F1C21B 2px solid;
    border-radius: 4px;
}

.dataset-warning-message {
    flex-grow: 1;
}

.dataset-warning-message h2 {
    font-size: 16px;
    text-align: left;
    margin-bottom: 4px;
}

.dataset-warning-message p {
    font-size: 14px;
    text-align: left;
    margin-top: 0px;
}

.dataset-warning-item {
    padding: 12px;
}
</style>
